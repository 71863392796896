import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Blogdetails.css"; // Import CSS file for styling if you have one
import parse from "html-react-parser";
import { FaCalendar } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const BlogDetails = () => {
  const [Loading, setLoading] = useState(true);
  const [blogDetailData, setBlogDetailData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [PopularBlogData, setPopularBlogData] = useState();
  const [PopularDataLoading, setPopularDataLoading] = useState(true);

  const { id } = useParams();
  useEffect(() => {
    const apiData = {
      id: id,
    };

    const GetBlogApi = async (apiData) => {
      setLoading(true);
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/get_blog?blog_id=${apiData.id}`
      );

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setBlogDetailData(res.data.responseObj.result);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const apiData = {
      companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
    };

    const getPopularAPI = async (apiData) => {
      const data = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/getAllPopularBlog?search=&page_no=1&rows_per_page=10&company_id=${apiData.companyid}`
      );
      

      return data;
    };

    getPopularAPI(apiData).then((res) => {
      setPopularDataLoading(true);
      setCategories(res.data.responseObj.category);
      setPopularBlogData(res.data.responseObj.result);
      setPopularDataLoading(false);
    });
  }, []);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogdetails/${data}`);
  };

  const handleCategoryClick = (value) => {
    navigate(`/blog/${value}`);
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  return (
    <div className="blogDetails-a">
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{
            backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`,
          }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Blog Details</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to={"/blog"}>Blog</Link>
              </li>
              <li>Blog Details</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="team" className="team pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Blog Details</h2>
          </div>

          {Loading ? (
            <div style={{ textAlign: "center" }}>
              <h4>Loading...</h4>
            </div>
          ) : (
            <div
              className="row aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="col-lg-8 col-md-6">
                <div className="blog-details-left">
                  <div className="col-lg-12">
                    <div className="member member-one1">
                      <img
                        src={`${blogDetailData.blog_image}`}
                        className="img-fluid"
                        alt="Blog Image"
                      />
                      <div className="member-content">
                        <h2 className="blog-towbox th-bd">
                          <div className="d-flex gap-2">
                            {blogDetailData.category
                              .split(",")
                              .map((categoryItem) => {
                                return (
                                  <div
                                    className="blogCardCategory-bd"
                                    key={categoryItem}
                                    onClick={() => {
                                      handleCategoryClick(categoryItem);
                                      handleLinkClick();
                                    }}
                                  >
                                    {categoryItem}
                                  </div>
                                );
                              })}
                          </div>
                          <div className="d-flex card0date">
                            <FaCalendar />
                            &nbsp;
                            {formatDate(blogDetailData.created_on)}
                          </div>
                        </h2>
                        <h2>{blogDetailData.blog_title}</h2>
                        <p>{parse(blogDetailData.content)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Blog Content */}

              <div className="col-lg-4 col-md-6">
                <div className="blog-details-right">
                  <div className="col-lg-12 p-2">
                    <div className="mt-1">
                      <h6>Popular articles</h6>
                    </div>

                    {PopularDataLoading ? (
                      <div style={{ textAlign: "center" }}>
                        <h6>Loading...</h6>
                      </div>
                    ) : (
                      <div className="PopularBlogWrap">
                        {PopularBlogData.length ? 
                        <>
                          {PopularBlogData?.map((item, index) => {
                          return (
                            <div
                              className="card bg-light-subtle mt-3 PopularBlogCard"
                              onClick={() => {
                                handleCardClick(item.blog_id);
                                handleLinkClick();
                              }}
                            >
                              <img
                                src={item.blog_image}
                                className="card-img-top"
                                alt="Blog Image"
                              />
                              <div className="card-body">
                                <div className="text-section">
                                  {/* <h5 className="card-title fw-bold">
                                    {item.blog_title}
                                  </h5> */}
                                  <p
                                    title={item.blog_title}
                                    className="card-text"
                                  >
                                    {item.blog_title}
                                  </p>
                                  <span id="categorySpan">
                                    {item.category.split(",")[0].trim()}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        </>
                        : <div className="EmptyPopularData">No Popular Article Present</div>}
                        
                      </div>
                    )}

                    <hr />

                    <div className="mt-3">
                      <h6>Category</h6>
                    </div>
                    <div className="d-flex gap-2 flex-wrap">
                      {PopularDataLoading ? (
                        <div style={{ textAlign: "center" }}>
                          <h6>Loading...</h6>
                        </div>
                      ) : (

                        
                        <>
                        {categories.length ? categories?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="blogCategory"
                              onClick={() => {
                                handleCategoryClick(item);
                                handleLinkClick();
                              }}
                            >
                              {item}
                            </div>
                          );
                        }): <div className="EmptyPopularData">No Category Present </div>}
                        
                        
                        </>
                      )}
                    </div>

                    <hr />

                    <div>
                      <h6> Follow us</h6>
                    </div>

                    <div>
                      <div className=" blogSocialLinks-bd">
                        <a
                          href="https://www.facebook.com/suugam.spotrack" target="_blank" 
                          className="facebook"
                        >
                          <FaFacebook />
                        </a>
                        <a
                          href="https://www.youtube.com/@Suugam.Spotrack" target="_blank"
                          className="youtube"
                        >
                          <FaYoutube />
                        </a>
                        <a
                          href="https://www.instagram.com/spotrack.in_/" target="_blank"
                          className="instagram"
                        >
                          <FaInstagram />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/suugamspotrack/" target="_blank"
                          className="linkedin"
                        >
                          <FaLinkedin />
                        </a>
                        <a
                          href="https://x.com/i/flow/login?redirect_after_login=%2FSuugam_Spotrack" target="_blank"
                          className="twitter"
                        >
                          <FaXTwitter />
                        </a>
                      </div>

                      <hr />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Sidebar */}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogDetails;
