import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../Components/Home/Home";
import Carrer from "../../Components/Carrer/Carrer";
import AboutUs from "../../Components/About/About";
import Blog from "../../Components/Blog/Blog";
import Product from "../../Components/Product/Product";
import FAQ from "../Faq/Faq";
import BlogDetails from "../../Components/Blogdetails/Blogdetails";
import Enquiry from "../../Components/Enquiry/Enquiry";
import News from "../../Components/News/News";
import PrivacyPolicy from "../../Components/Privacy-policy/Privacy_policy";
import Services from "../../Components/Services/Services";
import TermsAndConditions from "../../Components/TermsAndConditions/TermsAndConditions";
import Contact from "../../Components/Contact/Contactus";
import BlogSearch from "../BlogSearch/BlogSearch";
import BlogSearchCategory from '../BlogSearchCategory/BlogSearchCategory'

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/career" element={<Carrer />} />

      <Route path="/aboutus" element={<AboutUs />} />

      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:value" element={<BlogSearchCategory/>}/>
      <Route path="/blog/search/:value" element={<BlogSearch/>}/>

      <Route path="/product" element={<Product />} />

      <Route path="/faq" element={<FAQ />} />

      <Route path="/blogdetails/:id" element={<BlogDetails />} />

      <Route path="/enquiry" element={<Enquiry />} />

      <Route path="/news" element={<News />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/services" element={<Services />} />

      <Route path="/contact" element={<Contact />} />

      <Route path="/terms" element={<TermsAndConditions />} />

    </Routes>
  );
};

export default AppRouter;
