import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "../Accordion/Accordion";
import axios from "axios";
import Pagination from "../Pagination/Pagination";

const FAQ = () => {
  const itemsPerPage = 10;
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [flag, setFlag] = useState(false);
  const [faqData, setFaqData] = useState();

  useEffect(() => {
    const func = async () => {
      setFlag(true);
      const companyid = "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641";
      let res = await axios.get(
        `https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/faq/get_all_faq?company_id=${companyid}&search&page_no=${currentPage}&rows_per_page=${itemsPerPage}`
      );

      setCount(res.data.responseObj.count);
      // console.log("object", res);
      res = res.data.responseObj.result;
      setFaqData(res);
    };
    func();

    setTimeout(() => {
      setFlag(false);
    }, 100);
  }, [currentPage]);

  const totalPages = Math.ceil(count / itemsPerPage);
  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>FAQ</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/" onClick={handleLinkClick}>
                  Home
                </Link>
              </li>
              <li>Frequently Asked Questions</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="faq" className="faq">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div
            className="row justify-content-center aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-lg-10">
              <div className="accordion accordion-flush" id="faqlist">
                {flag ? (
                  <div style={{ textAlign: "center" }}>
                    <h4>Loading...</h4>
                  </div>
                ) : (
                  faqData &&
                  faqData.map((item, index) => (
                    <Accordion
                      index={index}
                      header={item.question}
                      body={item.answer}
                    />
                  ))
                )}
              </div>

              <div className="pagination-faq" onClick={handleLinkClick}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  handleLinkClick={handleLinkClick}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
