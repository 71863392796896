import React, { useEffect } from "react";
import "./index.css";
import AppRouter from "./Components/Router/Router.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Navbar from "./Components/Navbar/Navbar.jsx";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect( () => {
    setTimeout(() => {
     AOS.init({
       duration: 1000,
       easing: 'ease-in-out',
       once: true,
       mirror: false
     });  
    }, 500);
    
 },[])
  return (
    <div className="App ">
      <div>
        <Navbar />
      </div>
      <div>
        <AppRouter />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
