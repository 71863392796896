import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import "./BlogSearchCategory.css";
import parse from 'html-react-parser';
import { IoIosSearch } from "react-icons/io";
import { FaCalendar } from "react-icons/fa";

const BlogSearchCategory = () => {
  const [Loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const itemsPerPage = 9;
  const [searchBlog, setSearchBlog] = useState("");

  
  let {value} = useParams();
  useEffect(() => {
    const apiData = {
      itemPerPage: itemsPerPage,
      currentPage: currentPage,
      companyid: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
      Category:value,
    };

    const GetBlogApi = async (apiData) => {
        setLoading(true);
      const { data } = await axios.get(`https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/blog/getAllByCategory?search=${value}&page_no=${apiData.currentPage}&rows_per_page=${apiData.itemPerPage}&company_id=${apiData.companyid}`)

      return data;
    };

    GetBlogApi(apiData)
      .then((res) => {
        setBlogData(res.responseObj.result);
        setCount(res.responseObj.count);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error fetching blogs", error);
        toast.error("Something went wrong ");
        setLoading(false)
      });
  }, [currentPage, value]);

  const totalPages = Math.ceil(count / itemsPerPage);

  const navigate = useNavigate();
  const handleCardClick = (data) => {
    navigate(`/blogdetails/${data}`);
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSearch = (value) => {
    navigate(`/blog/search/${value}`);
  };
  
  return (
    <div className="blog-a">
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Blog</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Blog</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="team" className="team pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Blog</h2>
          </div>

          {Loading ? (
            <div style={{ textAlign: "center" }}>
              <h4>Loading...</h4>
            </div>
          ) :
          <div
            className="row aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="blogHeading ">
                <div className="blogCategoryHeadign">
                  <h1>{value}</h1>
                </div>
                <form onSubmit={() => {
                    handleSearch(searchBlog);
                  }} className="blogSearchForm-c">
                  <div className="input-groupSearch-c">
                    <input
                      type="search"
                      placeholder="What're you searching for?"
                      aria-describedby="button-addon1"
                      className="form-control border-0 bg-light"
                      value={searchBlog}
                      onChange={(e) => setSearchBlog(e.target.value)}
                    />
                    <div className="input-group-append bg-light">
                      <button
                        id="button-addon1"
                        type="submit"
                        className="btn btn-link text-primary "
                      >
                        <IoIosSearch />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            {blogData?.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 d-flex">
                <div className="member">
                  <img
                    src={`${item.blog_image}`}
                    className="img-fluid blogImg-a"
                    alt="Blog post"
                  />
                  <div className="member-content">
                    <h2 className="blog-towbox">
                      {/* <span>
                        <i className="fa-solid fa-user"></i>By{" "}
                        {item.blog_author}
                      </span> */}
                      <span className="d-flex">
                        <FaCalendar/>&nbsp;
                        {formatDate(item.created_on)}
                      </span>
                    </h2>
                    <h4 className="blogTitle-a">{item.blog_title}</h4>
                    <span>{item.category.split(",")[0].trim()}</span>
                    <p className="blogContent-a">{parse(item.content)}</p>
                    <div className="read-more-btn readMore" onClick={() =>{ handleCardClick(item.blog_id); handleLinkClick()}}>
                      Read More<i className="fa-solid fa-arrow-right-long"></i>
                    </div>
                    {/* Uncomment and use social links if needed */}
                    {/* <div className="social">
                      <a href="#"><i className="bi bi-twitter"></i></a>
                      <a href="#"><i className="bi bi-facebook"></i></a>
                      <a href="#"><i className="bi bi-instagram"></i></a>
                      <a href="#"><i className="bi bi-linkedin"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}

          </div>}

        </div>
      </section>
      {/* End Blog Section */}
{
  totalPages>1 ? 
    <div className="pagination-a" onClick={handleLinkClick}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          handleLinkClick={handleLinkClick}
        />
      </div>: ""
  }

      
    </div>
  );
};

export default BlogSearchCategory;
