import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div 
          className="page-header d-flex align-items-center" 
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Privacy Policy</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= About Us Section ======= */}
      <section id="about" className="about">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-12 content order-last privacy-text order-lg-first">
              <h3>Privacy & Policy</h3>
              <p>
                We regard your information as significant and release this Privacy Statement to inform you what information we collect and how we use it to personalize and continually improve your experience.
              </p>
              <h3>Information We Collect</h3>
              <p>
                <strong>a. Personal Information:</strong> We do not collect Personal Information. "Personal Information" is information that identifies you or another person, such as your first name and last name, physical addresses, email addresses, telephone, fax, SSN, information stored within your device.
              </p>
              <p>
                <strong>b. Non-personal Information:</strong> We collect your non-personal information when you visit our website. Information you provide. We may collect your information when you communicate with us or you give us.
              </p>
              <p><strong> c.</strong>  We are only getting your mobile device Id.</p>
              <h3>How We Use Information</h3>
              <p>
                <strong>a. Personal Information:</strong> We do not store Personal Information and therefore we do not disclose your Personal Information.
              </p>
              <p>
                <strong>b. Non-Personal Information:</strong> We do not sell, trade, or otherwise transfer to outside parties your information. We do not combine Non-Personal Information with Personal Information (such as combining your name with your unique User Device number).
              </p>
              <p><strong>c. We will use Device Number for updating/deleting GCMkey from our app's database.</strong></p>
              <p><strong>d. Legal Reasons:</strong> We will access, use or disclose your information with other organizations or entities for reasons that are required by any applicable law, regulation, legal process or enforceable governmental request; detect, prevent, or otherwise address fraud, security or technical issues; protect against harm to the rights, property or safety of our company, our users or the public as required or permitted by law.</p>

              <h3>Security</h3>
              <p>
                Our company is very concerned about safeguarding the confidentiality of your information. We do not collect Personal Information, and we employ administrative, physical, and electronic measures designed to protect your Non-Personal Information from unauthorized access and use. Please be aware that no security measures that we take to protect your information are absolutely guaranteed to avoid unauthorized access or use of your Non-Personal Information which is impenetrable.
              </p>
              <h3>Sensitive Information</h3>
              <p>
                We ask that you not send us, and you not disclose, any sensitive Personal Information (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, sexual orientation, criminal background or membership in past organizations, including trade union memberships) on or through an Application, the Services or the Site or otherwise to us.
              </p>

              <h3> Children </h3>
              <p>
              We do not provide service focus on Children. If you are under 18, you may use our website when you are with a parent or guardian.
              </p>
              <h3>Changes</h3>
              <p>
              Our Privacy Policy may change which will not reduce your rights under this Privacy Policy from time to time, we will post any privacy policy changes on this page, so please review it periodically. If you do not agree to any modifications to this Policy, your could immediately stop all use of all the Services. Your continued use of the Site following the posting of any modifications to this Policy will constitute your acceptance of the revised Policy. Please note that none of our employees or agents has the authority to vary any of our Policies.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End About Us Section */}
    </>
  );
};

export default PrivacyPolicy;
