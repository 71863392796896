import React, { useEffect, useState } from "react";
import "./Home.css";
import about from '../../assets/img/about.png'
import icon1 from '../../assets/img/icon1.png'
import icon2 from '../../assets/img/icon2.png' 
import icon3 from '../../assets/img/icon3.png' 
import gpsMiddle from '../../assets/img/gps-middle.jpg'
import features11 from '../../assets/img/features-11.jpg'
import features2 from '../../assets/img/features-2.jpg'
import features3 from '../../assets/img/features-3.jpg'
import features4 from '../../assets/img/features-4.jpg'
import features5 from '../../assets/img/features-5.jpg'
import features6 from '../../assets/img/features-6.jpg'
import features7 from '../../assets/img/features-7.jpg'
import features8 from '../../assets/img/features-8.jpg'
import Certificate1 from '../../assets/img/Certificate-1.png'
import Certificate2 from '../../assets/img/Certificate-2.png'
import logo1 from '../../assets/img/logo1.jpg'
import logo2 from '../../assets/img/logo2.jpg'
import logo3 from '../../assets/img/logo3.jpg'
import logo4 from '../../assets/img/logo4.jpg'
import logo5 from '../../assets/img/logo5.jpg'
import logo6 from '../../assets/img/logo6.jpg'
import logo7 from '../../assets/img/logo7.jpg'
import logo8 from '../../assets/img/logo8.jpg'
import logo9 from '../../assets/img/logo9.jpg'
import logo10 from '../../assets/img/logo10.jpg'
import logo11 from '../../assets/img/logo11.jpg'
import logo12 from '../../assets/img/logo12.jpg'
import logo13 from '../../assets/img/logo13.jpg'

const Home = () => {
  const [Company, setCompany] = useState(0);
  const [assets, setAssets] = useState(0);
  const [fuelsensor, setFuelsensor] = useState(0);
  // const [GPS, setGPS] = useState(0);

  useEffect(() => {
    increseCounter(0, 50, setCompany); //50+compnay
    increseCounter(0, 50, setAssets); //50K+ assets
    increseCounter(0, 100, setFuelsensor); // 30k+ fuel sensor
    // increseCounter(0, 40, setGPS); // 40K+ gps
  }, []);

  const increseCounter = (init, end, setfn) => {
    let b;
    if (end === 100) {
      b = 50;
    } else if (end === 40) {
      b = 130;
    } else {
      b = 100;
    }
    for (let a = init; a <= end; a++) {
      setTimeout(() => {
        setfn(a);
      }, b * a);
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h6 data-aos="fade-up">
                <strong className="time-change">It’s Time to Change</strong>
              </h6>
              <p className="mb-3" data-aos="fade-up" data-aos-delay="100">
                Precision Vehicle & Fuel Tracking with our
              </p>
              <h2 data-aos="fade-up">BEST GPS TECHNOLOGY</h2>
             
              <div className="row gy-4" data-aos="fade-up" data-aos-delay="4">
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                    
                      className="purecounter"
                    >
                      {Company}+
                    </span>
                    <p>Company</p>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span className="purecounter">{assets}K+</span>
                    <p>Assets</p>
                  </div>
                </div>

                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="1453"
                      data-purecounter-duration="6"
                      className="purecounter"
                    >
                      {fuelsensor}+
                    </span>
                    <p>Completed Projects</p>
                  </div>
                </div>

                {/* <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="32"
                      data-purecounter-duration="6"
                      className="purecounter"
                    >
                      {GPS}K+
                    </span>
                    <p>Running Projects</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Services Section */}
      <section
        id="featured-services"
        className="featured-services"
        style={{ display: "none" }}
      >
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-lg-3 col-md-6 service-item d-flex"
              data-aos="fade-up"
            >
              <div className="icon flex-shrink-0">
                <i className="fa-solid fa-cart-flatbed"></i>
              </div>
              <div>
                <h4 className="title">Real Time Cargo Tracking</h4>
                <p className="description">
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi sint occaecati cupiditate non provident
                </p>
                <a
                  href="service-details.html"
                  className="readmore stretched-link"
                >
                  <span>Learn More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* Repeat similar blocks for other services */}
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img
                src={about}
                className="img-fluid about-photo"
                alt="About Us"
              />
            </div>
            <div className="col-lg-6 content order-last order-lg-first">
              <h3>Welcome To Suugam</h3>
              <p>
                Choose our Fleet Management Solution to gain control over your
                fleet, reduce operational costs, and improve service quality.
                Experience the benefits of a smarter, more efficient fleet
                management system today.
              </p>
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <img
                    className="icon-photo"
                    src={icon1}
                    alt="Fleet Management"
                    style={{ width: "40px" }}
                  />
                  <div>
                    <h5>Fleet Management Solution</h5>
                    <p>
                      GPS monitoring, inventory and trap management, detailed
                      reports and analytics, and real-time notifications.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <img
                    classname="icon-photo"
                    src={icon2}
                    alt="Fuel monitoring"
                    style={{ width: "50px" }}
                  />
                  <div>
                    <h5>Fuel Monitoring Solution</h5>
                    <p>
                      Fuels pilferage, waste detection and fuel usage analytics
                      and reports.{" "}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <img
                    classname="icon-photo"
                    src={icon3}
                    style={{ width: "50px" }}
                    alt="alt"
                  />
                  <div>
                    <h5>E-lock Solution</h5>
                    <p>
                      Remote lock/Unlock built in GPS tracking geofencing ,and
                      real time notifications
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="service" className="services pt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Our Services</h2>
          </div>
          <div className="row gy-4">
            {/* Repeat card items with different icons and texts */}
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-gears"></i>
                </div>
                <h3 className="stretched-link">Adaptable performance</h3>
                <p>
                  Our product effortlessly adjusts to your needs, boosting
                  efficiency and simplifying your tasks.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                </div>
                <h3 className="stretched-link">Built to last</h3>
                <p>
                  Experience unmatched durability that goes above and beyond
                  with lasting investment.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-regular fa-thumbs-up"></i>
                </div>
                <h3 className="stretched-link">Great user experience</h3>
                <p>
                  Integrate our product into your routine with an intuitive and
                  easy-to-use interface
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-regular fa-lightbulb"></i>
                </div>
                <h3 className="stretched-link">Innovative functionality</h3>
                <p>
                  Stay ahead with features that set new standards, addressing
                  your evolving needs better than the rest.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-headset"></i>
                </div>
                <h3 className="stretched-link">Reliable support</h3>
                <p>
                  Count on our responsive customer support, offering assistance
                  that goes beyond the purchase.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy d-flex flex-column">
                <div className="card-img card-img-one">
                  <i className="fa-brands fa-searchengin"></i>
                </div>
                <h3 className="stretched-link">Precision in every detail</h3>
                <p>
                  Enjoy a meticulously crafted product where small touches make
                  a significant impact on your overall experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call To Action Section */}
      <section id="call-to-action" className="call-to-action p-0">
        <div data-aos="zoom-out">
          <div className="row justify-content-center">
            <div className="col-lg-12 gps-middle text-center">
              <img src={gpsMiddle} alt="Call to Action" />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <div className="container">
          {/* Repeat feature items with different images and texts */}
          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5">
              <img
                src={features11}
                className="img-fluid product-photo"
                alt="Feature 1"
              />
            </div>
            <div className="col-md-7">
              <h3>Monitor All Your Assets</h3>
              <p className="fst-italic">
                Simplify the way your assets move and perform from a single
                dashboard. Go beyond basic track-and-trace with a power-packed
                combination of vehicle tracking, performance scorecards,
                insightful reports, and real-time alerts. Suugam is best in
                IOT services.
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5 order-1 order-md-2">
              <img
                src={features2}
                className="img-fluid product-photo"
                alt=""
              />
            </div>
            <div className="col-md-7 order-2 order-md-1">
              <h3>Protect Your Vehicles & Cargo</h3>
              <p className="fst-italic">
                Keep your vehicles, cargo, and drivers safe with the power of Al
                and lOT. Combine advanced video telematics, state-of-the-art
                sensors, remote immobilsation, and SOS alerts to be your own
                fleet safety officer.
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5">
              <img
                src={features3}
                className="img-fluid product-photo"
                alt="Feature 3"
              />
            </div>
            <div className="col-md-7">
              <h3>Enhance Your Fuel Economy</h3>
              <p className="fst-italic">
                Lower fuel costs and optimise your operations with powerful
                sensors. Utilise rich analytics, fuel efficiency reports,
                instant refuelling and drainage alerts, and increase fuel
                efficiency.
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
              {/* Uncomment and use the list if needed */}
              {/* <ul>
            <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i className="bi bi-check"></i> Ullam est qui quos consequatur eos accusamus.</li>
          </ul> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5 order-1 order-md-2">
              <img
                src={features4}
                className="img-fluid product-photo"
                alt="Feature 2"
              />
            </div>
            <div className="col-md-7 order-2 order-md-1">
              <h3>Manage Your Driver & Their Potential</h3>
              <p className="fst-italic">
                Gain real-time visibility into driver behaviour and
                productivity. Leverage ADAS, DMS, gamified scorecards,
                geofencing, and trip management to improve driver retention and
                performance.
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5">
              <img
                src={features5}
                className="img-fluid product-photo"
                alt="Feature 5"
              />
            </div>
            <div className="col-md-7">
              <h3>Product Features</h3>
              <p className="fst-italic">
                We specialize in automated systems for remote tracking and
                monitoring with sensors for pressure, liquid & fuel levels,
                ultrasonic level meters, and radar level meters. Our team is
                committed to providing excellent solutions that meet the needs
                of diverse users.
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
              {/* Uncomment and use the list if needed */}
              {/* <ul>
            <li><i className="bi bi-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i className="bi bi-check"></i> Ullam est qui quos consequatur eos accusamus.</li>
          </ul> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5 order-1 order-md-2">
              <img
                src={features6}
                className="img-fluid product-photo"
                alt="Feature 6"
              />
            </div>
            <div className="col-md-7 order-2 order-md-1">
              <h3>Fuel Sensor </h3>
              <p className="fst-italic">
                The first wireless capacitive fuel level sensor on the market
                with Bluetooth Low Energy (BLE) technology. This isn't just
                another innovation; it's an evolutionary leap into the wireless
                future of transport telematics!
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5">
              <img
                src={features7}
                className="img-fluid product-photo"
                alt="Feature 7"
              />
            </div>
            <div className="col-md-7">
              <h3>Transform Fleet Technology With Us</h3>
              <p className="fst-italic">
                With a network of valued partners around the world, we aim to
                deliver best-in-class fleet management solutions to businesses
                everywhere. Be a part of the Spotrack Collective and join our
                mission to democratize access to fleet technology across the
                globe.
              </p>
            </div>
          </div>

          <div
            className="row gy-4 align-items-center features-item"
            data-aos="fade-up"
          >
            <div className="col-md-5 order-1 order-md-2">
              <img
                src={features8}
                className="img-fluid product-photo"
                alt="Feature 8"
              />
            </div>
            <div className="col-md-7 order-2 order-md-1">
              <h3>Together, We Achieve Greatness!</h3>
              <p className="fst-italic">
                Together, we'll continue to innovate, inspire, and make a
                difference. Our mission is to keep pushing boundaries and
                reaching for new heights!
              </p>
              {/* <div className="explore-btn1">
                <a href="contact.html">Explore</a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div>
        <h2
          style={{
            fontWeight: "700",
            fontSize: "30px",
            color: "#0a45bb",
            display: "flex",
            justifyContent: "Center",
            marginTop:"50px"
          }}
        >
          Achievements
        </h2>

        <div className="certificates ">
          <div className="certificate1 ">
            <img  src={Certificate1} alt="alt" />
          </div>
          <div className="certificate2 ">
            <img   src={Certificate2} alt="alt" />
          </div>
        </div>
      </div>

      <div class="slider">
        <div class="slide-track">
          <div class="sllide">
            <img
              src={logo6}
              height="100"
              width="250"
              alt="logo6"
            />
          </div>
          <div class="sllide">
            <img
              src={logo7}
              height="100"
              width="250"
              alt="logo7"
            />
          </div>
          <div class="sllide">
            <img
              src={logo8}
              height="100"
              width="250"
              alt="logo8"
            />
          </div>
          <div class="sllide">
            <img
              src={logo9}
              height="100"
              width="250"
              alt="logo9"
            />
          </div>
          <div class="sllide">
            <img
              src={logo1}
              height="100"
              width="250"
              alt="logo1"
            />
          </div>

          <div class="sllide">
            <img
              src={logo12}
              height="70"
              width="250"
              alt="logo12"
            />
          </div>
          <div class="sllide">
            <img
              src={logo13}
              height="90"
              width="250"
              alt="logo13"
            />
          </div>

          <div class="sllide">
            <img
              src={logo2}
              height="100"
              width="250"
              alt="logo2"
            />
          </div>
          <div class="sllide">
            <img
              src={logo3}
              height="100"
              width="250"
              alt="logo3"
            />
          </div>
          <div class="sllide">
            <img
              src={logo10}
              height="70"
              width="250"
              alt="logo10"
            />
          </div>

          <div class="sllide">
            <img
              src={logo4}
              height="100"
              width="250"
              alt="logo4"
            />
          </div>
          <div class="sllide">
            <img
              src={logo5}
              height="100"
              width="250"
              alt="logo5"
            />
          </div>
          <div class="sllide">
            <img
              src={logo11}
              height="70"
              width="250"
              alt="logo11"
            />
          </div>

          <div class="sllide">
            <img
              src={logo6}
              height="100"
              width="250"
              alt="logo6"
            />
          </div>
          <div class="sllide">
            <img
              src={logo7}
              height="100"
              width="250"
              alt="logo7"
            />
          </div>
          <div class="sllide">
            <img
              src={logo8}
              height="100"
              width="250"
              alt="logo8"
            />
          </div>
          <div class="sllide">
            <img
              src={logo9}
              height="100"
              width="250"
              alt="logo9"
            />
          </div>
          <div class="sllide">
            <img
              src={logo1}
              height="100"
              width="250"
              alt="logo1"
            />
          </div>

          <div class="sllide">
            <img
              src={logo12}
              height="70"
              width="250"
              alt="logo12"
            />
          </div>
          <div class="sllide">
            <img
              src={logo13}
              height="90"
              width="250"
              alt="logo13"
            />
          </div>

          <div class="sllide">
            <img
              src={logo2}
              height="100"
              width="250"
              alt="logo2"
            />
          </div>
          <div class="sllide">
            <img
              src={logo3}
              height="100"
              width="250"
              alt="logo3"
            />
          </div>
          <div class="sllide">
            <img
              src={logo11}
              height="70"
              width="250"
              alt="logo11"
            />
          </div>
          <div class="sllide">
            <img
              src={logo4}
              height="100"
              width="250"
              alt="logo4"
            />
          </div>
          <div class="sllide">
            <img
              src={logo5}
              height="100"
              width="250"
              alt="logo5"
            />
          </div>
          <div class="sllide">
            <img
              src={logo10}
              height="70"
              width="250"
              alt="logo10"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
