import React from 'react';
// import './TermsAndConditions.css'; // Import CSS file if you have specific styles
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <main id="main">
      {/* ======= Breadcrumbs ======= */}
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Terms & Conditions</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Terms & Conditions</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= About Us Section ======= */}
      <section id="about" className="about">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-12 content order-last privacy-text order-lg-first">
              <h3>Terms & Conditions</h3>
              <p>
                Your privacy matters to Suugam. This policy explains how we collect, process, use, share, and protect information. The Privacy Policy covers the Mobile Application or offline if any.
              </p>
              <p>BY ACCEPTING THE CUSTOMER TERMS AND CONDITION, YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY.</p>
              <p>Please review the following carefully so that you understand our privacy practices. This Privacy Policy is incorporated by reference into Terms and Conditions.</p>
              <p>"Mobile Application" shall mean application provided by us on the Device to access the Services.</p>
              <h3>WHAT INFORMATION DO WE COLLECT?</h3>
              <p>
                <strong> INFORMATION YOU PROVIDED TO US:</strong> We may ask you to provide us with certain Protected information. We may collect this information through various means and in various places through the Services, account login information, contact us forms, or when you otherwise interact with us. When we sign up ask you userid and password. And password information we store in encrypted mode. This information is for lawful purpose connected with our Services and necessary to be collected by us for such purpose.
              </p>
              <p>
                <strong>INFORMATION WE COLLECT AS YOU ACCESS AND USE OUR SERVICES:</strong> In addition to any Protected information or other information that you choose to submit to us, we collect certain information whenever you visit or interact with the Services.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End About Us Section */}
    </main>
  );
};

export default TermsAndConditions;
