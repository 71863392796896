import React from "react";
import { Link } from "react-router-dom";
import product1 from '../../assets/img/product1.jpg'
import product2 from '../../assets/img/product2.jpg'
import product3 from '../../assets/img/product3.jpg'
import fuel1 from '../../assets/img/fuel1.jpg'
import fuel2 from '../../assets/img/fuel2.jpg'
import dashboard1 from '../../assets/img/dashboard1.jpg'
import dashboard2 from '../../assets/img/dashboard2.jpg'
import dashboard3 from '../../assets/img/dashboard3.jpg'
import dashboard4 from '../../assets/img/dashboard4.jpg'
import dashboard5 from '../../assets/img/dashboard5.jpg'
import dashboard6 from '../../assets/img/dashboard6.jpg'

const Product = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Product</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Product</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="about" className="about pt-4">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2 style={{ fontSize: "24px" }}>
              increase productivity with full stack <br /> Asset Managment{" "}
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 position-relative align-self-start order-lg-last order-first">
              <img
                src={product1}
                className="img-fluid"
                alt="Wireless fuel sensor"
              />
            </div>
            <div className="col-lg-8 content order-last order-lg-first">
              <h3>Wireless Fuel Sensor</h3>
              <p className="fst-italic">
                The first wireless capacitive fuel level sensor on the market
                with Bluetooth Low Energy (BLE) technology. This isn't just
                another innovation; it's an evolutionary leap into the wireless
                future of transport telematics! Installing and configuring
                traditional fuel level sensors takes a long time. The lack of
                wires eliminates the tedious, time-consuming, and costly process
                of laying cables through the entire vehicle system. No wires -
                No problem.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 position-relative align-self-start order-lg-last order-first">
              <img
                src={product2}
                className="img-fluid"
                alt="GPS"
              />
            </div>
            <div className="col-lg-8 content order-last order-lg-end">
              <h3>GPS</h3>
              <p className="fst-italic">
                FMB120 is a small and professional tracker with internal
                high-gain GSM and GNSS antennas. It collects device coordinates
                and other useful data and transfers them via the GSM network to
                the server. This device is ideal for applications needing remote
                location tracking: fleet management, car rental companies, taxi
                companies, public transport, logistics companies, personal cars,
                and more. FMB120 can perform tasks on remote objects, such as
                monitoring engine status and controlling truck doors.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 position-relative align-self-start order-lg-last order-first">
              <img
                src={product3}
                className="img-fluid"
                alt="RPM"
              />
            </div>
            <div className="col-lg-8 content order-last order-lg-first">
              <h3>RPM</h3>
              <p className="fst-italic">
                Our Engine RPM Sensor is specifically designed for heavy
                machines and vehicles. It monitors engine RPM via electric
                signals from the alternator of the vehicle. The measured value
                is proportional to the engine RPM, hence a small calibration is
                required.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="application-interface"
        className="about application-interface pt-4"
      >
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Application Interface</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12 mb-4 content order-last order-lg-first">
              <p className="fst-italic">
                <strong>Fleet Management Software:</strong>Fleet management
                software includes real-time GPS tracking and telematics for
                monitoring vehicle locations and driver behaviors, maintenance
                management for scheduling and logging services, and fuel
                management to track consumption and costs. The software also
                facilitates route optimization to enhance operational
                efficiency, driver management for maintaining profiles and
                compliance, and asset management for overseeing vehicle and
                equipment inventories.
              </p>
              <p className="fst-italic">
                <strong>GPS Tracking System:</strong> Suugam  GPS tracking
                system could provide real-time location information for vehicles
                in a fleet.
              </p>
              <p className="fst-italic">
                <strong>Fuel Sensor Integration:</strong> This integration
                allows for efficient fuel management, reducing the risk of fuel
                theft and optimizing fuel consumption.
              </p>
              <p className="fst-italic">
                <strong>Data Analytics:</strong> The software could offer remote
                monitoring capabilities, allowing managers to track and control
                vehicles from a centralized dashboard.
              </p>
            </div>
            <div className="col-lg-6 content order-last order-lg-first mb-3">
              <div className="image-hover-text-container">
                <div className="image-hover-image">
                  <img src={fuel1} alt="Fuel Management" />
                </div>
                <div className="image-hover-text">
                  <div className="image-hover-text-bubble">
                    <span className="image-hover-text-title">
                      Fuel Management
                    </span>
                    Fuel Management
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 content order-last order-lg-first mb-3">
              <div className="image-hover-text-container">
                <div className="image-hover-image">
                  <img src={fuel2} alt="Asset Management" />
                </div>
                <div className="image-hover-text">
                  <div className="image-hover-text-bubble">
                    <span className="image-hover-text-title">
                      Asset Management
                    </span>
                    Asset Management
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Application Interface</h2>
          </div>
          <div className="row align-items-center">
            {/* slider */}
            <div
              id="carouselExampleControls"
              className="carousel carousel-dark slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="card-wrapper container-sm d-flex justify-content-around">
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard1}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">Web Portal</h5>
                      </div>
                    </div>
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard2}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">App Portal</h5>
                      </div>
                    </div>
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard3}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">Web Portal</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="card-wrapper container-sm d-flex justify-content-around">
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard4}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">Web Portal</h5>
                      </div>
                    </div>
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard5}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">Web Portal</h5>
                      </div>
                    </div>
                    <div className="card card-two" style={{ width: "21rem" }}>
                      <img
                        src={dashboard6}
                        className="card-img-top"
                        alt="Dashboard"
                      />
                      <div className="card-body web-portal-text">
                        <h5 className="card-title">Web Portal</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            {/* slider */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
