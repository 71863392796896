import React from "react";
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";
const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  handleLinkClick,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mb-4 mt-4">
      <ul className="pagination ">
        <li className="page-item ">
          <button
            disabled={currentPage === 1}
            className="page-link h-8 hover:disabled:cursor-not-allowed"
            onClick={() => {
              onPageChange(currentPage - 1);
              handleLinkClick();
            }}
          >
            <GrPrevious />
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button
              className="page-link h-8 items-center flex"
              // style={{background:"#00297c", color:"white"}}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            disabled={currentPage === totalPages}
            className="page-link h-8 hover:disabled:cursor-not-allowed"
            onClick={() => {
              onPageChange(currentPage + 1);
              handleLinkClick();
            }}
          >
            <GrNext />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
