import React, { useState } from "react";
import axios from "axios";
import validator from "validator";
import toast from 'react-hot-toast'
import quoteBg from '../../assets/img/quote-bg.jpg'
import { Link } from "react-router-dom";

const Enquiry = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [send, setSend] = useState("Send Message");

  const [error, setError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    number:"",
  });


  const handlePostRequest = async (event) => {
    try {
      event.preventDefault();

      let tempError = {};

      // name validation
      if (!name) {
        setError((prev) => ({ ...prev, name: "Name Is Required" }));
        tempError.name = "Name Is Required";
      } else if (
        !validator.isAlpha(name.replace(/\s+/g, ""), "en-US", { ignore: " " })
      ) {
        setError((prev) => ({
          ...prev,
          name: "Name should only contain letters or spaces",
        }));
        tempError.name = "Name should only contain letters or spaces";
      } else {
        setError((prev) => ({ ...prev, name: "" }));
        tempError.name = "";
      }


      // email validation
      if (!email) {
        setError((prev) => ({ ...prev, email: "Email Is Required" }));
        tempError.number = "Email Is Required";
      } else if (!validator.isEmail(email)) {
        setError((prev) => ({ ...prev, email: "Invalid Email" }));
        tempError.email = "Invalid Email";
      } else {
        setError((prev) => ({ ...prev, email: "" }));
        tempError.email = "";
      }

      // subject validation
      if (!subject) {
        setError((prev) => ({ ...prev, subject: "Subject is Required" }));
        tempError.subject = "subject Is Required";
      } else {
        setError((prev) => ({ ...prev, subject: "" }));
        tempError.subject = "";
      }
 
    const regExForContact = /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if(!number) {
        setError(prev => ({...prev, number: "Number Is Required"}))
        tempError.number = "Number Is Required"
        
      }
      else if (!regExForContact.test(number)) {
        setError(prev => ({...prev, number: "Invalid Mobile Number"}))
        tempError.number = "Invalid Mobile Number"
      }
      else {
        setError(prev => ({...prev, number: ""}))
        tempError.number = ""
      }

      // message validation
      if (!message) {
        setError((prev) => ({ ...prev, message: "Message is Required" }));
        tempError.message = "Message Is Required";
      } else {
        setError((prev) => ({ ...prev, message: "" }));
        tempError.message = "";
      }


      if (
        tempError.message ||
        tempError.email ||
        tempError.name ||
        tempError.subject ||
        tempError.number
      ) {
        return;
      }

      const data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        company_id: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
        contact: number,
      };

      setSend("Sending...");

      let res = await axios.post(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/inquiry/add-inquiry",
        data
      );
      let to = res.data.result[0].result;
      toast.success(to);

      setSend("Send Message");
      setEmail("");
      setName("");
      setMessage("");
      setSubject("");
      setNumber("");

      
    } catch (error) {
      setEmail("");
      setName("");
      setMessage("");
      setSubject("");
      setNumber("");
      setSend("Send Message");
      toast.error(error.response.data.message)      
    }
  };

  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Enquiry</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>Get a Quote</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= Get a Quote Section ======= */}
      <section id="get-a-quote" className="get-a-quote">
        <div className="container" data-aos="fade-up">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6">
              <div>
                <img
                  className="quote-bg"
                  src={quoteBg}
                  alt="Quote Background"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setError((prev) => ({ ...prev, name: "" }));
                      }}
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                    <div style={{ color: "red" }}>{error.name}</div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError((prev) => ({ ...prev, email: "" }));
                      }}
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                    <div style={{ color: "red" }}>{error.email}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        setError((prev) => ({ ...prev, subject: "" }));
                      }}
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                    <div style={{ color: "red" }}>{error.subject}</div>
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Your Phone number"
                      required
                      onChange={(e) => {
                        setNumber(e.target.value);
                        setError((prev) => ({ ...prev, number: "" }));
                      }}
                      value={number}
                    />
                    <div style={{ color: "red" }}>{error.number}</div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setError((prev) => ({ ...prev, message: "" }));
                    }}
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                  <div style={{ color: "red" }}>{error.message}</div>
                </div>
                <div class="my-3">
                  {/* <div class="loading">Loading</div> */}
                  {/* <div class="error-message">{loading}</div> */}
                  {/* <div class="sent-message">Your message has been sent. Thank you!</div> */}
                </div>

                <div className="text-center">
                  <button className="sendmessage" onClick={handlePostRequest}>
                    {send}
                  </button>
                </div>
              </div>
            </div>
            {/* End Quote Form */}
          </div>
        </div>
      </section>
      {/* End Get a Quote Section */}
    </>
  );
};

export default Enquiry;
