import React from "react";
import axios from "axios";
import { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [send, setSend] = useState("Send Message");
  const [number, setNumber] = useState("");

  const [error, setError] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
  });

  const handlePostRequest = async (event) => {
    try {
      event.preventDefault();

      let tempError = {};

      if (!name) {
        setError((prev) => ({ ...prev, name: "Name Is Required" }));
        tempError.name = "Name Is Required";
      } else if (
        !validator.isAlpha(name.replace(/\s+/g, ""), "en-US", { ignore: " " })
      ) {
        setError((prev) => ({
          ...prev,
          name: "Name should only contain letters or spaces",
        }));
        tempError.name = "Name should only contain letters or spaces";
      } else {
        setError((prev) => ({ ...prev, name: "" }));
        tempError.name = "";
      }

      if (!email) {
        setError((prev) => ({ ...prev, email: "Email Is Required" }));
        tempError.number = "Email Is Required";
      } else if (!validator.isEmail(email)) {
        setError((prev) => ({ ...prev, email: "Invalid Email" }));
        tempError.email = "Invalid Email";
      } else {
        setError((prev) => ({ ...prev, email: "" }));
        tempError.email = "";
      }

      if (!subject) {
        setError((prev) => ({ ...prev, subject: "Subject is Required" }));
        tempError.subject = "subject Is Required";
      } else {
        setError((prev) => ({ ...prev, subject: "" }));
        tempError.subject = "";
      }

      if (!message) {
        setError((prev) => ({ ...prev, message: "Message is Required" }));
        tempError.message = "Message Is Required";
      } else {
        setError((prev) => ({ ...prev, message: "" }));
        tempError.message = "";
      }

      const regExForContact =
        /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if (!number) {
        setError((prev) => ({ ...prev, number: "Number Is Required" }));
        tempError.number = "Number Is Required";
      } else if (!regExForContact.test(number)) {
        setError((prev) => ({ ...prev, number: "Invalid Mobile Number" }));
        tempError.number = "Invalid Mobile Number";
      } else {
        setError((prev) => ({ ...prev, number: "" }));
        tempError.number = "";
      }

      if (
        tempError.message ||
        tempError.email ||
        tempError.name ||
        tempError.subject ||
        tempError.number
      ) {
        return;
      }

      const data = {
        name: name,
        email: email,
        subject: subject,
        message: message,
        company_id: "5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641",
        contact: number,
      };

      setSend("Sending...");

      let res = await axios.post(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/inquiry/add-inquiry",
        data
      );
      let to = res.data.result[0].result;
      toast.success(to);

      setSend("Send Message");
      setEmail("");
      setName("");
      setMessage("");
      setSubject("");
      setNumber("");
      setSend("Send Message");

    } catch (error) {
      setEmail("");
      setName("");
      setMessage("");
      setSubject("");
      setNumber("");
      setSend("Send Message");
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      {/* Breadcrumbs */}
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: `url(${require("../../assets/img/page-header.jpg")})`}}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Contact</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div className="info-item d-flex">
                <i className="bi bi-phone flex-shrink-0"></i>
                <div>
                  <h4>Call:</h4>
                  <p>+91-97846-32155</p>
                </div>
              </div>
              {/* End Info Item */}

              <div className="info-item d-flex">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h4>Email:</h4>
                  <p>info@suugam.com</p>
                </div>
              </div>
              {/* End Info Item */}

              <div className="info-item d-flex">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h4>Location:</h4>
                  <p>
                    Corporate Office - Yogi tower Mahalaxmi Nagar Road D-Block
                    Malviya Nagar, Jaipur - 302017 Rajasthan
                  </p>
                </div>
              </div>
              {/* End Info Item */}
            </div>

            <div className="col-lg-8">
              <div>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setError((prev) => ({ ...prev, name: "" }));
                      }}
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                    <div style={{ color: "red" }}>{error.name}</div>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setError((prev) => ({ ...prev, email: "" }));
                      }}
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />

                    <div style={{ color: "red" }}>{error.email}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        setError((prev) => ({ ...prev, subject: "" }));
                      }}
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                    <div style={{ color: "red" }}>{error.subject}</div>
                  </div>
                  <div className="col-md-6 form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Your Phone number"
                      required
                      onChange={(e) => {
                        setNumber(e.target.value);
                        setError((prev) => ({ ...prev, number: "" }));
                      }}
                      value={number}
                    />
                    <div style={{ color: "red" }}>{error.number}</div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setError((prev) => ({ ...prev, message: "" }));
                    }}
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                  ></textarea>
                  <div style={{ color: "red" }}>{error.message}</div>
                </div>

                <div className="text-center mt-3">
                  <button className="sendmessage" onClick={handlePostRequest}>
                    {send}
                  </button>
                </div>
              </div>
            </div>
            {/* End Contact Form */}
          </div>

          <div className="mt-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28477.020981148267!2d75.76931331083986!3d26.85179380000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5d69950e079%3A0xe71d3d472f718bd6!2sSUUGAM%20TECHKNOW%20WORLD%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1720591973549!5m2!1sen!2sin"
              width="100%"
              height="340"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          {/* End Google Maps */}
        </div>
      </section>
      {/* End Contact Section */}
    </div>
  );
};

export default Contact;
